import { FC, useState } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./components/Dashboard/Dashboard";
import { JurisageDataAPI } from "./lib/JurisageDataAPI";
import { MyJrContextProvider } from "./lib/MyJrContext";

function App() {
  const [context] = useState({
    api: new JurisageDataAPI(),
  });

  return (
    <MyJrContextProvider value={context}>
      <BrowserRouter basename="dashboard">
        <Routes>
          <Route path="register" element={<Navigate to="/" />} />
          <Route path="login" element={<Navigate to="/" />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path=":citationId" element={<Dashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </MyJrContextProvider>
  );
}

const Layout: FC = () => {
  return (
    <div className="App">
      <div className="Main">
        <Outlet />
      </div>
    </div>
  );
};

export default App;
