import CaseDocumentWidget from "../CaseDocumentWidget/CaseDocumentWidget";
import CompassTopicsWidget from "../CompassTopicsWidget/CompassTopicsWidget";
import GenerateSummaryWidget from "../GenerateSummaryWidget/GenerateSummaryWidget";
import "./Grid.css";

const Grid: React.FC<{ data: any }> = (props) => {
  return (
    <div className="StaticGrid">
      <div className="grid-item GenerateSummaryWidget">
        <GenerateSummaryWidget id={props.data._id} data={props.data} />
      </div>
      <div className="grid-item CaseDocumentWidget">
        <CaseDocumentWidget id={props.data._id} data={props.data} />
      </div>
      <div className="grid-item CompassTopicsWidget">
        <CompassTopicsWidget id="compassTopicsWidget" data={props.data} />
      </div>
    </div>
  );
};

export default Grid;
