export interface JurisageLegalDocument {
  _id: string;
  citations?: { source_id: string; name: string; type: string }[];
  counsels?: {}[];
  court?: string;
  date?: string;
  imported?: string;
  judges?: { source_id: string; name: string }[];
  jurisdiction?: string;
  last_updated?: string;
  preferedCitationName?: string;
  provider?: string;
  source_id?: string;
  titles?: { source_id: string; name: string; type: string }[];
  uris?: { source_id: string; name: string; type: string }[];
}

export interface JurisageLegalDocumentAdditionalInfo<T> {
  _id: string;
  version: string | number;
  type: string;
  data: T;
  // TODO: Update with actual schema
}

export interface JurisageFILACData {
  runDate: string;
  sentences: { text: string; tags: string[] }[];
}

export class JurisageDataAPI {
  async getDocument(documentId: string): Promise<JurisageLegalDocument> {
    const storedToken = localStorage.getItem("storedToken");
    const BASE_URL =
      process.env.REACT_APP_BUILD_ENVIRONMENT !== "development"
        ? "https://api.jurisage.com/caselaw/document/"
        : "https://myjrapi-staging.azure-api.net/caselaw/document/";
    const url = BASE_URL + `${documentId}`;
    const resp = await fetch(url, { method: "GET", headers: { Authorization: `Bearer ${storedToken}` } });
    const data = await resp.json();
    return Promise.resolve(data.data);
  }

  async getAdditionalDocumentInfo(documentId: string, infoType: string) {
    const storedToken = localStorage.getItem("storedToken");
    const BASE_URL =
      process.env.REACT_APP_BUILD_ENVIRONMENT !== "development"
        ? "https://api.jurisage.com/caselaw/enrichment/"
        : "https://myjrapi-staging.azure-api.net/caselaw/enrichment/";
    const url = BASE_URL + `${documentId}/${infoType}`;
    const resp = await fetch(url, { method: "GET", headers: { Authorization: `Bearer ${storedToken}` } });
    if (resp.status === 404) {
      return Promise.resolve({});
    }
    const data = await resp.json();
    return Promise.resolve(data.data);
  }
}
