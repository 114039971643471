import { FC, PropsWithChildren, ReactNode, useContext, useEffect, useState } from "react";
import MyJrContext from "src/lib/MyJrContext";
import BaseWidget from "../BaseWidget/BaseWidget";
import "./CompassTopicsWidget.css";

interface CompassTopicsWidgetProps {
  id: string;
  actions?: ReactNode;
  data: {
    _id: string;
  };
}

interface KeynoteTopicObject {
  Values: {
    knt_number: number;
    knt_name: string;
    heading: string;
    parent_heading: string;
    subject_matter: string;
  };
  Keys: string[];
}

const CompassTopicsWidget: FC<PropsWithChildren<CompassTopicsWidgetProps>> = (
  props: PropsWithChildren<CompassTopicsWidgetProps>,
) => {
  const context = useContext(MyJrContext);
  const [content, setContent] = useState<Array<KeynoteTopicObject>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.data && props.data._id) {
      setLoading(true);
      const fetchData = async () => {
        const storedToken = localStorage.getItem("storedToken");
        const url =
          process.env.REACT_APP_BUILD_ENVIRONMENT !== "development"
            ? `https://api.jurisage.com/caselaw/enrichment/${props.data._id}/CASETOPICS`
            : `https://myjrapi-staging.azure-api.net/caselaw/enrichment/${props.data._id}/CASETOPICS`;
        const resp = await fetch(url, { method: "GET", headers: { Authorization: `Bearer ${storedToken}` } });
        if (resp.ok) {
          const data = await resp.json();
          const sortedData = data.sort((a: KeynoteTopicObject, b: KeynoteTopicObject) => {
            return (
              a["Values"].subject_matter.localeCompare(b["Values"].subject_matter) ||
              a["Values"].knt_number - b["Values"].knt_number
            );
          });
          setContent(sortedData);
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [context.api, props.data]);

  return (
    <BaseWidget id={props.id} title={"Case Topics"} subtitle={"Case Data"} subtitleColor={"#805ae6"}>
      <div className="CompassTopicsWidget">
        <ul className="CompassTopicsWidget__List">
          {content?.map((i: any, index: number) => {
            return (
              <li key={index}>
                <div>
                  <strong>{i?.Values?.subject_matter}</strong>
                </div>
                <div className="CompassTopicsWidget__Breadcrumb">
                  {i?.Values?.parent_heading} • {i?.Values?.heading} • {i?.Values?.knt_name}
                </div>
              </li>
            );
          })}
        </ul>
        {!loading && content && content.length === 0 && <>No topics found.</>}
      </div>
    </BaseWidget>
  );
};

export default CompassTopicsWidget;
