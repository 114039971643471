import { FC, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import MyJrContext from "src/lib/MyJrContext";
import { useWindowSize } from "src/utils/hooks/UseWindowSize";
import { ReactComponent as CourtIcon } from "../../assets/images/court.svg";
import { ReactComponent as ExpandIcon } from "../../assets/images/expand.svg";
import { ReactComponent as JudgesIcon } from "../../assets/images/judges.svg";
import Grid from "../Grid/Grid";
import SearchInput from "../SearchInput/SearchInput";
import "./Dashboard.css";

interface DashboardProps {}

const mockCitationResponse = {
  provider: "compass",
  id: "JCD-01-aa75381d73f3bc7b91768cb5cf22b74c",
  title: "Housen v. Nikolaisen et al.",
  version: "1.0",
  lastUpdated: "2022-06-06T06:04:18Z",
  created: "2001-10-02T00:00:00Z",
  data: {
    _id: "JCD-01-aa75381d73f3bc7b91768cb5cf22b74c",
    source_id: "DE-01F10GSCXPK0TH80W2ZY36V38T",
    provider: "compass",
    imported: "2022-09-24T08:00:29.398Z",
    pipeline_version: "batch-importer",
    last_updated: "2022-06-06T06:04:18Z",
    non_publishable: false,
    citations: [
      { source_id: "CI-01F10GSCY0YQS7CWCKNPJQHH68", name: "[2002] 2 SCR 235", type: "Official" },
      { source_id: "CI-01F10GSCY2VPB06125B29YR9XH", name: "2002 SCC 33", type: "Neutral" },
      { source_id: "CI-01F10GSCY9XDG7MDG3PCBTCSRJ", name: "(2002), 286 N.R. 1 (SCC)", type: "MLB Official" },
      { source_id: "CI-01G4VRNVJVPCQVDG03XG74P98E", name: "(2002), 286 NR 1 (SCC)", type: "Alt" },
      { source_id: "CI-01G4VSHPWZSR389QQQK1ZMD07F", name: "286 N.R. 1", type: "Alt" },
      { source_id: "CI-01G4VSTCE27HPNW31B836DQNDV", name: "[2002] 2 S.C.R. 235", type: "Official" },
    ],
    titles: [
      { source_id: "TI-01F10GSCXRP9QDCJ7PFEE0RZ1R", name: "Housen v. Nikolaisen et al.", type: "primary" },
      { source_id: "TI-01F10GSCXSDGFFSTDK7YRNH4QJ", name: "Housen v. Nikolaisen ", type: "alternate" },
    ],
    judges: [
      { source_id: "JU-01F3VDT1V30YXK7T5477SSRA5X", name: "Arbour" },
      { source_id: "JU-01F3VDVHJQTYBRWRZWVRNWW4ZM", name: "Bastarache" },
      { source_id: "JU-01F3VDY1X9FK0YGHVCZDC3983H", name: "Binnie" },
      { source_id: "JU-01F3VEF48R7SDG3GNMQVCSAPN5", name: "Gonthier" },
      { source_id: "JU-01F3VEMZPBWCJBJE5YQZAN1KSH", name: "Iacobucci" },
      { source_id: "JU-01F3VER2DH9X9AJKJ51NVDX63K", name: "L'Heureux-Dubé" },
      { source_id: "JU-01F3VEWT7A52906VAXP9CY8B5C", name: "LeBel" },
      { source_id: "JU-01F3VF1PTWRMQ67YSPT4XX9X7B", name: "Major" },
      { source_id: "JU-01F10GSCYETSQ1TDDJEC97FTJW", name: "McLachlin" },
    ],
    counsels: [],
    uris: [
      { source_id: "695604", uri: "http://compass.law/cases/c07be476-dd68-4e32-88b8-848e198c2fb5", type: "Private" },
      { source_id: "695606", uri: "http://vlex.com/ca/caselaw/2002_SCC_33", type: "Private" },
      { source_id: "695605", uri: "http://vlex.com/vid/680982585", type: "Public - vLex" },
      {
        source_id: "1213949",
        uri: "https://www.canlii.org/en/ca/scc/doc/2002/2002scc33/2002scc33.html",
        type: "Public - CanLII",
      },
    ],
    document_files: [
      {
        source_id: "DF-01F1131GMCYMSK2NXN31ECEA8M",
        type: "content",
        filetype: "text/html",
        storage_path:
          "https://jurisagedataassetstorage.blob.core.windows.net/caselawdocs/documents/compass/JCD-01-aa75381d73f3bc7b91768cb5cf22b74c/DF-01F1131GMCYMSK2NXN31ECEA8M.html",
      },
      {
        source_id: "DF-01F1131GMDX6VV76XZYQM1TM4G",
        type: "headnotes",
        filetype: "text/html",
        storage_path:
          "https://jurisagedataassetstorage.blob.core.windows.net/caselawdocs/documents/compass/JCD-01-aa75381d73f3bc7b91768cb5cf22b74c/DF-01F1131GMDX6VV76XZYQM1TM4G.html",
      },
    ],
    jurisdiction: "Federal Jurisdiction (Canada)",
    date: "2001-10-02T00:00:00Z",
    court: "Supreme Court of Canada",
    preferedCitationName: "2002 SCC 33",
    primaryUrl: "https://www.canlii.org/en/ca/scc/doc/2002/2002scc33/2002scc33.html",
  },
};

const Dashboard: FC<DashboardProps> = () => {
  const { citationId } = useParams();
  const context = useContext(MyJrContext);
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [submissionCount, setSubmissionCount] = useState(0);
  const [activeToken, setActiveToken] = useState("");
  const { width } = useWindowSize();

  const gridRef = useRef(null);

  const storedToken = localStorage.getItem("storedToken");
  const storedSubmissionCount = localStorage.getItem("submissionCount");

  useEffect(() => {
    let cancelled = false; // Avoid race conditions
    const fetchData = async () => {
      setLoading(true);

      if (citationId) {
        if (storedSubmissionCount) {
          setSubmissionCount(+storedSubmissionCount);
        }
        let extraHeaders = {};

        const userToken = storedToken ? storedToken : "";

        setActiveToken(userToken);
        if (userToken) {
          extraHeaders = {
            Authorization: `Bearer ${userToken}`,
          };
        }

        const BASE_URL =
          process.env.REACT_APP_BUILD_ENVIRONMENT !== "development"
            ? "https://api.jurisage.com/caselaw/document/"
            : "https://myjrapi-staging.azure-api.net/caselaw/document/";
        const url = BASE_URL + `${citationId}`;

        try {
          const resp = await fetch(url, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              ...extraHeaders,
            },
          });
          if (resp.ok) {
            const respJson = await resp.json();
            const respData = respJson.data;
            if (!cancelled) {
              try {
                document.title = `${
                  respData.titles[0].name ? respData.titles[0].name + " | Dashboard" : "Dashboard"
                } | Jurisage`;
              } catch (err) {}
              setData(respData);
              setLoading(false);
            }
          } else {
            if (resp.status === 429 || resp.status === 401) {
              setLoading(false);
            }
          }
        } catch (err) {
          setLoading(false);
        }
      } else {
        if (!cancelled) {
          setData(mockCitationResponse.data);
          setLoading(false);
        }
      }
    };

    // @NOTE: Temporarily remove this case for now
    if (citationId === "JCD-01-6dcb681ddbf45aa67e4e80133fa2f22c") {
      setLoading(false);
      return () => {
        cancelled = true;
      };
    }

    fetchData();

    return () => {
      cancelled = true;
    };
  }, [citationId, context, storedSubmissionCount, storedToken]);

  useEffect(() => {
    function setScrollHandlers() {
      const navEl = document.getElementById("nav");
      const el = document.getElementById("nav-slim");
      el!.classList.remove("NavBar--Sticky");

      const initialCoords = navEl?.getBoundingClientRect() as { top: number; bottom: number };
      const initialHeight = initialCoords.bottom - initialCoords.top;

      function applyScrollListener() {
        if (el) {
          // Adjusting for top nav on Wordpress page
          if (window.scrollY > initialHeight + (!width || width > 981 ? 92 : 92)) {
            el.classList.add("NavBar--Sticky");
          } else {
            el.classList.remove("NavBar--Sticky");
          }
        }
      }

      document.removeEventListener("scroll", applyScrollListener);
      document.addEventListener("scroll", applyScrollListener);
    }
    setScrollHandlers();
  }, [citationId, data, width]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };

    // Normalize timezones/remove offset
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.getTime() + userTimezoneOffset);
    return adjustedDate.toLocaleDateString("en-US", options as any);
  };

  const handleSubmit = () => {
    if (activeToken.length === 0) {
      setSubmissionCount(submissionCount + 1);
      localStorage.setItem("submissionCount", `${submissionCount + 1}`);
    }
  };

  return (
    <div className="Dashboard">
      <div id="nav" className="Dashboard__Header">
        <div className="Dashboard__Search">
          <SearchInput handleCountIncrement={handleSubmit}></SearchInput>
        </div>
        <div className={"Dashboard__Loading Section--Primary" + (loading ? " Dashboard__Loading--Active" : "")}>
          <div className="Dashboard__Inset">
            <div className="Dashboard__Spinner"></div>
          </div>
        </div>
        <div className="Dashboard__Summary">
          <div className="Dashboard__CitationInfo">
            <h1>
              <span>{data.titles && data.titles[0] && data.titles[0].name ? data.titles[0].name : "N/A"}</span>
            </h1>
            <div className="Dashboard__SummaryCitation">
              {data.preferedCitationName ? data.preferedCitationName : "N/A"}&nbsp;
              {data.primaryUrl && (
                <a href={data.primaryUrl} rel="noreferrer" target="_blank">
                  Full text
                  <ExpandIcon></ExpandIcon>
                </a>
              )}
            </div>
            <div className="Dashboard__SummaryCitation">
              <strong>Decision Date: </strong>
              <span>{data.date ? formatDate(data.date) : "N/A"}</span>
            </div>
          </div>
          <div className="Dashboard__CourtInfo">
            <div>
              <div>
                <div className="Dashboard__CourtInfoSection">
                  <div>
                    <CourtIcon></CourtIcon>
                  </div>
                  <div>
                    <strong>Court:</strong>
                    <br />
                    <span>{data.court ? data.court : "N/A"}</span>
                  </div>
                </div>
              </div>
              <div>
                <div className="Dashboard__CourtInfoSection">
                  <div>
                    <JudgesIcon></JudgesIcon>
                  </div>
                  <div>
                    <strong>Judge(s): </strong>
                    <br />
                    <div>
                      {data.judges &&
                        data.judges.length > 0 &&
                        data.judges.map((i: { id: string; name: string }, index: number) => {
                          return (
                            <span key={index}>
                              <>
                                {index > 0 ? ", " : ""}
                                {i.name}
                              </>
                            </span>
                          );
                        })}
                      {(!data.judges || data.judges.length === 0) && <span>N/A</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <strong>Docket number: </strong>
              <span>{data.docket_number ? data.docket_number : "N/A"}</span>
            </div>
          </div>
        </div>
      </div>
      <div id="nav-slim" className="Dashboard__Header--Slim">
        <div className={"Dashboard__Loading Section--Primary" + (loading ? " Dashboard__Loading--Active" : "")}>
          <div className="Dashboard__Inset">
            <div className="Dashboard__Spinner"></div>
          </div>
        </div>
        <div className="Dashboard__Summary">
          <div className="Dashboard__CitationInfo">
            <h1>
              {data.titles ? data.titles[0].name + ", " : ""}
              <span>{data.preferedCitationName ? data.preferedCitationName : "N/A"}&nbsp;</span>
              {data.primaryUrl && (
                <a href={data.primaryUrl} rel="noreferrer" target="_blank">
                  Full text
                  <ExpandIcon></ExpandIcon>
                </a>
              )}
            </h1>
          </div>
        </div>
      </div>
      <div ref={gridRef} className={"Dashboard__Grid-Container"}>
        <Grid data={data} />
      </div>
      <div style={{ background: "transparent", height: 15, width: "100%" }}></div>
    </div>
  );
};

export default Dashboard;
