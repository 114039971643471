import { FC, useEffect, useState } from "react";
import BaseWidget from "../BaseWidget/BaseWidget";
import "./CaseDocumentWidget.css";

interface CaseDocumentWidgetProps {
  id: string;
  data: {
    titles: {
      name: string;
    }[];
    document_files: DocumentFile[];
  };
}

interface DocumentFile {
  type: string;
  fileType: string;
  storage_path: string;
  created_at?: Date;
  updated_at?: Date;
  file_version?: number;
  original_filesize?: number;
}

const CaseDocumentWidget: FC<CaseDocumentWidgetProps> = (props) => {
  const [htmlContent, setHtmlContent] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      if (props.data && props.data.document_files) {
        let file;
        if (
          props.data.document_files.filter(
            (d: any) => d.created_at && d.updated_at && d.file_version && d.original_filesize,
          ).length === 0
        ) {
          file = props.data.document_files.find((d: any) => d.type === "content" && d.filetype === "text/html");
        } else {
          const fileVersions = props.data.document_files
            .filter((d: any) => d.type === "content" && d.filetype === "text/html")
            .map((d: any) => d.file_version);
          const maxVersion = Math.max(...fileVersions);
          file = props.data.document_files
            .filter((d: any) => d.type === "content" && d.filetype === "text/html" && d.file_version === maxVersion)
            .sort((a, b) => {
              if (a.original_filesize && b.original_filesize && a.original_filesize > b.original_filesize) {
                return -1;
              }
              return 1;
            })[0];
        }
        if (file) {
          fetch(file.storage_path, {
            method: "GET",
          })
            .then(async (resp) => {
              const bufferResponse = resp.clone(); // Clone response to get both text and buffer for charset conversion
              const response = await resp.text();
              const responseBuffer = await bufferResponse.arrayBuffer();

              // See answer here: https://stackoverflow.com/questions/42414839/convert-windows-1252-to-utf-8-with-js
              const isWindows1252 = response.includes("charset=windows-1252"); // Check whether the charset is windows-1252
              let elementHtml = response; // Basic response by default
              if (isWindows1252) {
                if (!("TextDecoder" in window)) throw new Error("TextDecoder is not available in the environment."); // Error if charset is specified but conversion is unavailable
                const windows1252Decoder = new TextDecoder("windows-1252"); // Choose desired charset
                const ui8array = new Uint8Array(responseBuffer); // Buffer to array
                const windows1252DecodedHtml = windows1252Decoder.decode(ui8array);
                const hasReplacementCharacters = response.includes("�"); // Includes replacement characters, so the charset hasn't been converted yet
                if (hasReplacementCharacters) {
                  elementHtml = windows1252DecodedHtml;
                }
              }

              const divElement = document.createElement("div"); // Create div to allow us to remove tags
              divElement.innerHTML = elementHtml; // Set HTML to be the response or decoded HTML

              function removeElementsByTagName(tagName: string) {
                const elements = divElement.getElementsByTagName(tagName);
                let i = elements.length;
                while (i--) {
                  if (elements[i].parentNode) {
                    elements[i].parentNode!.removeChild(elements[i]);
                  }
                }
              }
              [
                "script",
                "style",
                "meta",
                "title",
                "link",
                "svg",
                "xml",
                "comment",
                "embed",
                "listing",
                "noscript",
                "object",
                "plaintext",
                "xmp",
              ].forEach((i) => removeElementsByTagName(i)); // Strip most HTML tags

              setHtmlContent(divElement.innerHTML);
              setLoading(false);
            })
            .catch((err) => {
              throw new Error(err);
            });
        } else {
          setHtmlContent("");
          setLoading(false);
        }
      } else {
        setHtmlContent("");
        setLoading(false);
      }
    } catch (err) {
      throw err;
    }
  }, [props.id, props.data]);

  return (
    <BaseWidget id={props.id} title={""} subtitle={"Case Document"} subtitleColor={"#9500ff"} isPlaceholder={!!loading}>
      {props.data && props.data.titles && props.data.titles.length > 0 ? (
        <>
          <h1 className="Dashboard__DocumentTitle">{props.data.titles[0].name}</h1>
          {htmlContent.length > 0 ? (
            <div className="Dashboard__DocumentStart" style={{ marginBottom: "1em" }}>
              [Beginning of document]
            </div>
          ) : (
            <div className="Dashboard__DocumentStart">[Document not available]</div>
          )}
        </>
      ) : (
        <></>
      )}
      <div className="Dashboard__Document" dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
    </BaseWidget>
  );
};

export default CaseDocumentWidget;
