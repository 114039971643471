import { browserTracingIntegration, init, replayIntegration } from "@sentry/react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
const root = ReactDOM.createRoot(document.getElementById("main-content") as HTMLElement);

init({
  dsn: "https://b4f59fe54f70e02c9ef7486d91067f30@o120748.ingest.us.sentry.io/4507267857711104",
  integrations: [browserTracingIntegration(), replayIntegration()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

(async () => {
  const params = new URLSearchParams(window.location.search);

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_ID || "6643657c4bfcff0f83415fd0",
    context: {
      kind: "multi",
      user: { key: params.get("userId") },
      company: { key: params.get("companyId") },
    },
  });

  root.render(
    <LDProvider>
      <App />
    </LDProvider>
  );
})();

const menuElement = document.querySelector(".et_pb_row--with-menu") as HTMLElement;

if (menuElement) {
  // Button element exists only on the dashboard page
  const buttonElement = document.querySelector(".et_pb_section--with-menu .et_pb_button") as HTMLLinkElement;
  const dropdownElement = document.createElement("div");
  dropdownElement.id = "myjr-auth-dropdown";
  menuElement.appendChild(dropdownElement);
  if (buttonElement) {
    buttonElement.remove();
  }
}

reportWebVitals();
