import React, { FC, PropsWithChildren } from "react";

interface BaseWidgetProps {
  id: string;
  title: string;
  subtitle: string;
  subtitleColor?: string;
  actions?: React.ReactNode;
  menu?: React.ReactNode;
  isPlaceholder?: boolean;
  isVertical?: boolean;
}

const BaseWidget: FC<PropsWithChildren<BaseWidgetProps & React.HTMLAttributes<HTMLDivElement>>> = (
  props: PropsWithChildren<BaseWidgetProps & React.HTMLAttributes<HTMLDivElement>>,
) => {
  return (
    <div key={props.id} className={`Card ${props.className ?? ""}`}>
      <div className="Card__Grip"></div>
      <div
        className={
          "Card__Inner" +
          (props.isPlaceholder ? " Card__Inner--Locked" : "") +
          (props.isVertical ? " Card__Inner--Flex" : "")
        }
      >
        <div className="Card__Header">
          {props.actions ? props.actions : null}
          {props.menu ? props.menu : null}
        </div>
        <div className="Card__Title--Flex">
          <h3 style={{ color: props.subtitleColor ? props.subtitleColor : "#00aeef" }}>{props.subtitle}</h3>
          <h4>{props.title}</h4>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default BaseWidget;
