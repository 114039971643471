import { FC, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import useClickOutside from "../../utils/hooks/UseClickOutside";
import { useDebounce } from "../../utils/hooks/UseDebounce";
import "./SearchInput.css";

interface SearchInputProps {
  handleCountIncrement: Function;
  onChange?: Function;
}

const SearchInput: FC<SearchInputProps> = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const [highlighting, setHighlighting] = useState({});
  const [showPopup, setShowPopup] = useState(true);
  const debouncedQuery = useDebounce(searchQuery, 500);
  const navigate = useNavigate();

  const popupRef = useRef<HTMLDivElement>(null);
  useClickOutside(popupRef, () => {
    setShowPopup(false);
  });

  const searchForCitations = async (query: string) => {
    const provider = "compass";
    const resp = await fetch(
      `https://api.jurisage.com/solr/citations/select?df=citation&hl.fragsize=500&defType=dismax&q.op=AND&hl.fl=citation&hl.requireFieldMatch=true&hl=true&q=${query}&fq=provider:${provider}`,
    );
    const data = await resp.json();
    return data;
  };

  useEffect(() => {
    if (debouncedQuery) {
      setShowPopup(true);
      searchForCitations(debouncedQuery).then((results) => {
        const data = results.response.docs;
        const highlighting = results.highlighting;
        setResults(data);
        setHighlighting(highlighting);
      });
    } else {
      setShowPopup(false);
      setResults([]);
      setHighlighting({});
    }
    document.addEventListener("keydown", escFunction, false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  const handleClearClick = () => {
    setSearchQuery("");
    setShowPopup(false);
  };

  const formatCitation = (result: any) => {
    const highlightedResult = (highlighting as any)[result.id].citation.filter((x: any) => x);
    if (highlightedResult) {
      return highlightedResult;
    }
    return result.citation[0];
  };

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setShowPopup(false);
    }
  }, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (results.length > 0 && showPopup) {
      // Select first option and navigate to it
      setShowPopup(false);
      const path = generatePath("/:citationId", { citationId: (results[0] as { id: string }).id });
      navigate(path);
    }
  };

  return (
    <>
      <span className="Search">
        <SearchIcon className="SearchInput__Icon"></SearchIcon>
        <form className="SearchInput__Form" onSubmit={handleSubmit}>
          <input
            placeholder="Enter a citation..."
            className="SearchInput"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onClick={() => setShowPopup(true)}
          />
        </form>
        {searchQuery.length > 0 && (
          <button className="SearchInput__Close" onClick={handleClearClick}>
            &times;
          </button>
        )}
        {showPopup && results.length > 0 && (
          <div ref={popupRef} className="Search__Results">
            <ul className="Citations">
              {results.map((result: any) => (
                <button
                  key={result.id}
                  onClick={() => {
                    setShowPopup(false);
                    navigate(generatePath("/:citationId", { citationId: result.id }));
                  }}
                >
                  {<span dangerouslySetInnerHTML={{ __html: formatCitation(result) }}></span>}
                </button>
              ))}
            </ul>
          </div>
        )}
      </span>
    </>
  );
};

export default SearchInput;
