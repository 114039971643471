import { createContext } from "react";
import { JurisageDataAPI, JurisageLegalDocument } from "./JurisageDataAPI";

export interface MyJrAppContext {
  api: JurisageDataAPI;
  currentDocument?: JurisageLegalDocument;
}

const MyJrContext = createContext<MyJrAppContext>({
  api: new JurisageDataAPI(),
});

export const MyJrContextProvider = MyJrContext.Provider;

export default MyJrContext;
