import { useFlags } from "launchdarkly-react-client-sdk";
import { FC, FormEvent, PropsWithChildren, ReactNode, useEffect, useState } from "react";
import BaseWidget from "../BaseWidget/BaseWidget";
import "./GenerateSummaryWidget.css";

interface GenerateSummaryWidgetProps {
  id: string;
  actions?: ReactNode;
  data: {
    _id: string;
    titles: { name: string }[];
    citations: Citation[];
  };
}

interface Citation {
  name: string;
  type: string;
}

const genericErrorMessage = "An error occurred. Please try again later.";

const GenerateSummaryWidget: FC<PropsWithChildren<GenerateSummaryWidgetProps>> = (
  props: PropsWithChildren<GenerateSummaryWidgetProps>
) => {
  const [summaryExtra, setSummaryExtra] = useState("");
  const [summaryType, setSummaryType] = useState("LEGAL_CONCLUSIONS");
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCopyNotice, setShowCopyNotice] = useState(false);
  const flags = useFlags();

  useEffect(() => {
    setSummary("");
  }, [props.id]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const url =
      process.env.REACT_APP_BUILD_ENVIRONMENT !== "development"
        ? `https://api.jurisage.com/chat/summcase`
        : `https://myjrapi-staging.azure-api.net/chat/summcase`;

    try {
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jcd: props.id,
          type: summaryType,
          extra: summaryExtra.trim(),
          user: "dashboard_user",
        }),
      });
      setLoading(false);
      if (resp.ok) {
        const response = await resp.json();
        if (response.summary) {
          setSummary(response.summary);
        } else {
          setSummary(genericErrorMessage);
        }
      } else {
        setSummary(genericErrorMessage);
      }
      return false;
    } catch (err) {
      setLoading(false);
      setSummary(genericErrorMessage);
      return false;
    }
  };

  function formatCitationPrefix(): string {
    const citations = props.data.citations as Citation[];
    const neutralCitation = citations.find((citation: Citation) => citation.type.toLowerCase() === "neutral");
    const officialCitation = citations.find((citation: Citation) => citation.type.toLowerCase() === "official");
    const mlbOfficialCitation = citations.find((citation: Citation) => citation.type.toLowerCase() === "mlb official");
    const mlbUnofficialCitation = citations.find(
      (citation: Citation) => citation.type.toLowerCase() === "mlb unedited"
    );

    const formattedPrimaryCitations = [neutralCitation, officialCitation, mlbOfficialCitation]
      .filter((c) => c)
      .map((citation: Citation | undefined) => citation!.name)
      .slice(0, 2)
      .join(", ");
    const prefix = `${formatTitle(props.data.titles)}, ${
      formattedPrimaryCitations ? formattedPrimaryCitations : mlbUnofficialCitation ? mlbUnofficialCitation.name : ""
    } at https://jurisage.com/dashboard/${props.data._id} - Summary text automatically generated by Jurisage AI\n\n`;
    return prefix;
  }

  function formatTitle(titles: { name: string }[] | undefined) {
    return titles ? titles[0].name : "";
  }

  function copyTextToClipboard(text: string) {
    const copyFrom = document.createElement("textarea");
    copyFrom.textContent = formatCitationPrefix() + text;
    document.body.appendChild(copyFrom);
    copyFrom.select();
    document.execCommand("copy");
    copyFrom.blur();
    document.body.removeChild(copyFrom);
  }

  return (
    <BaseWidget id={props.id} title={"Generate Summary"} subtitle={"Case Summary"} isVertical={true}>
      <div style={{ height: "100%" }} className="SummaryWidget">
        <div style={{ height: "100%" }} className="SummaryWidget__ControlsArea">
          <form style={{ height: "100%" }} onSubmit={(e) => handleSubmit(e)}>
            {props.id && <input id="SummaryWidget__CaseId" name="jcd" value={props.id} disabled={true} hidden={true} />}
            <label className="SummaryWidget__FormText" htmlFor="SummaryWidget__SummaryType">
              Summary style:
            </label>
            <div className="SummaryWidget__SelectWrapper">
              <select id="SummaryWidget__SummaryType" name="type" onChange={(e) => setSummaryType(e.target.value)}>
                <option value="LEGAL_CONCLUSIONS">Legal conclusions</option>
                <option value="FACTS_250">Facts</option>
                <option value="BRIEF_FACTS_250">Brief facts</option>
                <option value="100_WORDS">100 word summary</option>
                <option value="200_WORDS">200 word summary</option>
                <option value="IRAC">IRAC highlight - Issue, Rule, Analysis, Conclusion</option>
                <option value="PARTIES_RESULT">"Who were the parties and what was the result"</option>
                <option value="TEST_IN_RESULT">Legal test used in result</option>
                <option value="FACTS">Just the facts</option>
                <option value="ISSUES">Issues</option>
              </select>
            </div>
            {flags.refineSummary && (
              <>
                <label className="SummaryWidget__FormText" htmlFor="SummaryWidget__Context">
                  Refine the summary with a specific direction:
                </label>
                <div className="SummaryWidget__ContextArea">
                  <textarea
                    id="SummaryWidget__Context"
                    name="extra"
                    rows={1}
                    value={summaryExtra}
                    onChange={(e) => setSummaryExtra(e.target.value)}
                  />
                  <button
                    className="SummaryWidget__ContextClear"
                    onClick={(e) => {
                      e.preventDefault();
                      setSummaryExtra("");
                      const element = document.getElementById("SummaryWidget__Context");
                      element?.focus();
                    }}
                  ></button>
                </div>
              </>
            )}
            <label className="SummaryWidget__FormText" htmlFor="SummaryWidget__Context">
              Summary will appear here:
            </label>
            <div className="SummaryWidget__SummaryArea">
              <textarea
                style={{ height: "100%" }}
                className="SummaryWidget__QuoteSummaryArea"
                rows={flags.refineSummary ? 6 : 7}
                readOnly={true}
                value={summary}
              ></textarea>
              {summary && (
                <button
                  className="SummaryWidget__Copy"
                  onClick={(e) => {
                    e.preventDefault();
                    copyTextToClipboard(summary);
                    if (!showCopyNotice) {
                      setTimeout(() => setShowCopyNotice(false), 2000);
                    }
                    setShowCopyNotice(true);
                  }}
                ></button>
              )}
              {showCopyNotice && <div className="SummaryWidget__CopyNotice">Copied!</div>}
            </div>
            <button type="submit" disabled={loading}>
              {!loading ? "Generate Summary" : "Loading Summary..."}
            </button>
          </form>
        </div>
      </div>
    </BaseWidget>
  );
};

export default GenerateSummaryWidget;
